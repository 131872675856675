import React from 'react'
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  VStack,
  HStack,
  Tooltip,
  Image,
} from '@chakra-ui/react'
import { useAppSelector } from '@/shared/app/hooks'
import { sessionState } from '@/shared/features/session.slice'
import { useGetCouponsQuery } from './services/coupon.service'
import { Link, useNavigate } from 'react-router-dom'
import { ApplicationLoader } from '@/shared/components/loaders/ApplicationLoader'
import { NucArrowLeft } from '@nucba-coding/nucba-ui'

export const Coupon = () => {
  const { id } = useAppSelector(sessionState)
  const { data, error, isLoading } = useGetCouponsQuery({ userId: id })
  const navigate = useNavigate()

  if (isLoading) {
    return <ApplicationLoader />
  }

  if (error || !data || data.length === 0) {
    return (
      <VStack
        color='white'
        maxW='60%'
        minH='100vh'
        mx='auto'
        p={8}
        spacing={12}
        justifyContent='center'
        align='center'
      >
        <Box textAlign='center'>
          <Heading size='lg' color='nucba.primary' mb={4}>
            No encontramos un cupón disponible 😞
          </Heading>
          <Text fontSize='md' color='gray.300' mb={8}>
            Si deseas un cupón, por favor contacta al sector de secretaría para
            solicitarlo. Estarán felices de ayudarte a obtenerlo.
          </Text>
          <Button
            as='a'
            href='https://api.whatsapp.com/send?phone=5491123946509&text=Hola,%20quiero%20comunicarme%20con%20secretaria%20acad%C3%A9mica%20de%20Nucba!'
            color='nucba.primary'
            variant='solid'
            size='lg'
            target='_blank'
          >
            Solicitar a secretaría
          </Button>
        </Box>
      </VStack>
    )
  }

  const coupon = data[0]

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text)
  }

  return (
    <VStack
      color='white'
      maxW='80%'
      minH='100vh'
      mx='auto'
      p={8}
      spacing={12}
      align='start'
    >
      <VStack spacing={6} textAlign='left' w='full' alignItems='self-start'>
        <HStack
          gap={2}
          align='center'
          _hover={{ cursor: 'pointer', textDecoration: 'underline' }}
          onClick={() => navigate(-1)}
        >
          <NucArrowLeft />
          <Text>Volver atrás</Text>
        </HStack>

        <Heading size='lg' textAlign='start'>
          ¡Regalá hasta un 20% off! 🚀
        </Heading>
        <Text>
          En NUCBA buscamos disminuir la brecha del desconocimiento. Hoy podés
          acceder a nuestro sistema de referidos y sumar descuentos para seguir
          estudiando.
        </Text>
        <Text fontSize='md' color='gray.300' textAlign='center'>
          Alumnos referidos:{' '}
          <Text as='span' color='nucba.primary'>
            {coupon.subsCount.aggregate.count}
          </Text>
        </Text>
        <HStack
          border='1px solid'
          borderColor='gray.700'
          borderRadius='md'
          w='full'
          p={4}
          spacing={6}
          divider={<Text color='gray.500'>|</Text>}
        >
          <Text fontWeight='bold' fontSize='sm' textAlign='center' flex='1'>
            Copiá tu código
          </Text>
          <Tooltip label='Copiar' placement='top' hasArrow>
            <Button
              color='nucba.primary'
              variant='solid'
              size='md'
              flex='1'
              rightIcon={<span>📎</span>}
              mx={4}
              onClick={() => copyToClipboard(coupon?.code)}
            >
              {coupon?.code}
            </Button>
          </Tooltip>
          <VStack align='center' spacing={1} textAlign='center' flex='1' mx={4}>
            <Text fontSize='sm' fontWeight='bold' color='nucba.primary'>
              Cupón Referido
            </Text>
            <Text fontSize='xs' color='gray.300'>
              Sobre el total de cualquier curso o carrera. No acumulable con
              otras promociones
            </Text>
          </VStack>
        </HStack>
      </VStack>

      <VStack spacing={10} align='stretch' w='full'>
        <Flex align='center' gap={6} w='full'>
          <Box w='50%'>
            <Text
              fontSize='3xl'
              fontWeight='bold'
              bg='nucba.primary'
              borderRadius='full'
              color='white'
              w='50px'
              h='50px'
              textAlign='center'
              lineHeight='50px'
            >
              1
            </Text>
            <Heading size='md' my={2}>
              Ingresá a Galileo
            </Heading>
            <Text>
              En la sección de Ingresar copiá y compartí el código de referidos
              que asignamos.
            </Text>
          </Box>
          <Box
            w='50%'
            bg='gray.700'
            h='150px'
            borderRadius='md'
            textAlign='center'
          >
            <Image src='https://res.cloudinary.com/dcatzxqqf/image/upload/v1735245946/Galileo-Platform/Images/inscriptions/cupon-paso-0_ln7s5i.png' />
          </Box>
        </Flex>

        <Flex align='center' gap={6} w='full'>
          <Box
            w='50%'
            bg='gray.700'
            h='150px'
            borderRadius='md'
            textAlign='center'
          >
            <Image
              objectFit='cover'
              src='https://res.cloudinary.com/dcatzxqqf/image/upload/v1735246300/Galileo-Platform/Images/inscriptions/cupon-paso-1_lzwysh.png'
            />
          </Box>
          <Box w='50%'>
            <Text
              fontSize='3xl'
              fontWeight='bold'
              bg='nucba.primary'
              borderRadius='full'
              color='white'
              w='50px'
              h='50px'
              textAlign='center'
              lineHeight='50px'
            >
              2
            </Text>
            <Heading size='md' my={2}>
              Compartí tu código
            </Heading>
            <Text>
              Compartí tu código a quien querés que se inscriba, quien podrá
              utilizarlo en cualquiera de los cursos o carreras de la{' '}
              <a
                href='https://galileo.nucba.com.ar/inscripcion'
                target='_blank'
                rel='noopener noreferrer'
              >
                <Text
                  as='span'
                  color='nucba.primary'
                  _hover={{ textDecoration: 'underline' }}
                >
                  web.
                </Text>
              </a>
            </Text>
          </Box>
        </Flex>
      </VStack>
    </VStack>
  )
}
