import React from 'react'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Navigate,
} from 'react-router-dom'

// ROUTES HANDLES

import { Root } from './root'
import { Auth } from './auth'
import { CohortRoot } from './cohort'
import { AssignmentRoot } from './assignment'
import { ExercisesRoot } from './exercises'
import { InscriptionLayoutWithSR } from '@/container/InscriptionLayout'

// PAGES

import { Login } from '@/auth/pages/Login'
import { StepOne } from '@/auth/pages/changePassword/StepOne'
import { StepTwo } from '@/auth/pages/changePassword/StepTwo'
import { StepThree } from '@/auth/pages/changePassword/StepThree'
import { Home } from '@/education/cohorts/pages/Home'
import { Cohort } from '@/education/cohorts/pages/Cohort'
import { Course } from '@/education/cohorts/pages/Course'
import { Assignment } from '@/education/assignments/pages/Assignment'
import { SubmitAssignment } from '@/education/assignments/pages/SubmitAssignment'
import { AssignmentDevolution } from '@/education/assignments/pages/AssignmentDevolution'
import { Exercises } from '@/education/assignments/pages/Exercises'
import { Exercise } from '@/education/assignments/pages/Exercise'
import { ExerciseDetails } from '@/education/assignments/pages/ExerciseDetails'
import { CreateAccount } from '@/inscription/pages/CreateAccount'
import { Inscription } from '@/inscription/pages/Inscription'
import { PendingPay } from '@/inscription/pages/PendingPay'
import { SuccessPay } from '@/inscription/pages/SuccessPay'
import { PersonalInfo } from '@/inscription/pages/PersonalInfo'
import { TyC } from '@/inscription/pages/TyC'
import { LoginInscription } from '@/inscription/pages/LoginInscription'
import { Blog } from '@/education/edpresso/pages/Blog'
import { Post } from '@/education/edpresso/pages/Post'

import { Error } from '@/shared/pages/Error'
import { Profile } from '@/education/cohorts/pages/Profile'
import { Class } from '@/education/cohorts/pages/Class'
import { Contract } from '@/education/subscriptions/pages/Contract'
import { AuditLog } from '@/education/subscriptions/pages/AuditLog'
import { Subscriptions } from '@/education/subscriptions/pages/Subscriptions'
import { CreateAccountDuo } from '@/inscription/pages/duo/CreateAccountDuo'
import { PersonalInfoDuo } from '@/inscription/pages/duo/PersonalInfoDuo'
import { InvitationSuccess } from '@/inscription/pages/InvitationSuccess'
import { TransferInvoice } from '@/education/subscriptions/pages/TransferInvoice'
import { ContractRoot } from './contract'
import { InProcessPay } from '@/inscription/pages/InProcessPay'
import { SubscriptionsRoot } from './subscriptions'
import { Plans } from '@/inscription/pages/Plans'
import { SocialMedia } from '@/inscription/pages/SocialMedia'
import { EdpressoRoot } from './edpresso'
import { Assignments } from '@/education/cohorts/pages/Assignments'
import { Coupon } from '@/education/coupon/pages/MyCoupon'

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      {/* EDUCATION */}

      <Route path='/' element={<Root />} errorElement={<Error />}>
        <Route index element={<Home />} />
        <Route path='perfil' element={<Profile />} />
        <Route path='mi-cupon' element={<Coupon />} />
        <Route path='blog' element={<EdpressoRoot />}>
          <Route index element={<Blog />} />
          <Route path=':spaceSlug/:postSlug' element={<Post />} />
        </Route>
        <Route path='mis-suscripciones' element={<SubscriptionsRoot />}>
          <Route index element={<Subscriptions />} />
          <Route path=':subscriptionId'>
            <Route
              path='adjuntar-pago/:invoiceId'
              element={<TransferInvoice />}
            />
            <Route path='contrato' element={<ContractRoot />}>
              <Route index element={<Contract />} />
              <Route path=':contractId/registro' element={<AuditLog />} />
            </Route>
          </Route>
        </Route>
        <Route path='camada/:code' element={<CohortRoot />}>
          <Route index element={<Cohort />} />
          <Route path='asignaciones/:studentId' element={<Assignments />} />
          <Route path='curso/:course'>
            <Route index element={<Course />} />
            <Route path='clase/:classId'>
              <Route index element={<Class />} />
            </Route>
            <Route path='entregas' element={<ExercisesRoot />}>
              <Route index element={<Exercises />} />
              <Route path='entrega/:exerciseId'>
                <Route index element={<Exercise />} />
                <Route path='detalles' element={<ExerciseDetails />} />
              </Route>
            </Route>
            <Route path='asignacion/:assignmentId' element={<AssignmentRoot />}>
              <Route index element={<Assignment />} />
              <Route path='entregar' element={<SubmitAssignment />} />
              <Route path='devolucion' element={<AssignmentDevolution />} />
            </Route>
          </Route>
        </Route>
      </Route>

      {/* AUTH */}

      <Route path='auth' element={<Auth />}>
        <Route index element={<Navigate to='login' replace />} />
        <Route path='login' element={<Login />} />
        <Route path='cambiar-password'>
          <Route path='paso-uno' element={<StepOne />} />
          <Route path='paso-dos' element={<StepTwo />} />
          <Route path='paso-tres' element={<StepThree />} />
        </Route>
      </Route>

      {/* INSCRIPTION */}

      <Route path='inscripcion' element={<InscriptionLayoutWithSR />}>
        <Route index element={<Inscription />} />
        <Route path=':product' element={<Plans />} />
        <Route path='crear-cuenta' element={<CreateAccount />} />
        <Route path='crear-cuenta-duo' element={<CreateAccountDuo />} />
        <Route path='datos-personales' element={<PersonalInfo />} />
        <Route path='datos-personales-duo' element={<PersonalInfoDuo />} />
        <Route path='invitacion-ya-aceptada' element={<InvitationSuccess />} />
        <Route path='redes-sociales' element={<SocialMedia />} />
        <Route path='ingresar-cuenta' element={<LoginInscription />} />
        <Route path='pago-pendiente' element={<PendingPay />} />
        <Route path='pago-en-proceso' element={<InProcessPay />} />
        <Route path='pago-exitoso' element={<SuccessPay />} />
        <Route path='TyC' element={<TyC />} />
      </Route>
    </>,
  ),
)
