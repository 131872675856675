import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  Box,
  HStack,
  Stack,
  Text,
  ToastId,
  useToast,
  Link as ChakraLink,
} from '@chakra-ui/react'
import { ButtonSecondary } from '@nucba-coding/nucba-ui'

import { MyCohortes } from '../components/MyCohortes'
import { PageHeading } from '../../shared/components/PageHeading'
import { Isologo } from '@/shared/components/Isologo'
import { useSessionState } from '@/shared/features/session.slice'
import { Hero } from '@/shared/components/Hero'
import { openInNewTab } from '@/shared/utils'
import { HeaderNovu } from '@/shared/components/Novu'
import { useGetPendingContractsQuery } from '@/shared/services/userQuery.service'
import { CONTRACT_STATUS } from '@/shared/types'
import { ConfirmationModal } from '@/shared/components/ConfirmationModal'
import { useContractPrefetch } from '@/education/subscriptions/services/contract-query.service'
import { useGetUserOpenTransferInvoiceQuery } from '@/education/subscriptions/services/billing-query.service'
import { TOAST_DEFUALT_OPTIONS } from '@/shared/constants'
import { DateTime } from '@/shared/helpers'

export const Home = () => {
  const toast = useToast({
    ...TOAST_DEFUALT_OPTIONS,
    status: 'info',
  })
  const navigate = useNavigate()
  const { firstname, id } = useSessionState()

  const { data: contract } = useGetPendingContractsQuery(id, {
    refetchOnMountOrArgChange: true,
  })

  const { data: invoice } = useGetUserOpenTransferInvoiceQuery(id)

  useEffect(() => {
    let toastId: ToastId

    if (invoice) {
      toastId = toast({
        title: 'Boleta pendiente de pago',
        description: (
          <Text as='span'>
            Tienes una boleta pendiente que vence el{' '}
            <b>
              {DateTime.createFromString(invoice.dueDate, false).formatDate({
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })}
            </b>
            . Por favor,{' '}
            <ChakraLink
              href={`/mis-suscripciones/${invoice.subscriptionId}/adjuntar-pago/${invoice.id}`}
              textDecoration='underline'
              _hover={{ fontWeight: 600 }}
            >
              realiza el pago
            </ChakraLink>{' '}
            para evitar interrupciones en tu suscripción.
          </Text>
        ),
        isClosable: false,
        duration: null,
      })
    }

    return () => toast.close(toastId)
  }, [invoice])

  const prefetchSubscriptionContract = useContractPrefetch(
    'getSubscriptionContract',
    {
      force: false,
    },
  )

  return (
    <>
      {contract && contract.status === CONTRACT_STATUS.PENDIENTE ? (
        <ConfirmationModal
          isNotCancelable
          modalIsOpen
          title='“Hey 👋🏻, solo resta que firmes tu instancia en NUCBA para regularizar tu alumnado. Haz click en el siguiente botón para completar el paso'
          action={() =>
            navigate(`mis-suscripciones/${contract.subscriptionId}/contrato`)
          }
          btnProps={{
            onMouseEnter: () =>
              prefetchSubscriptionContract(contract.subscriptionId),
          }}
        />
      ) : null}

      <Hero image='https://res.cloudinary.com/dcatzxqqf/image/upload/v1679871403/Galileo-Platform/Images/banner/homeHero_muieju.webp'>
        <HStack pos='absolute' top={1} right={8}>
          <HStack position='relative' alignItems='flex-start'>
            <Box
              h={2}
              w={2}
              rounded='full'
              bg='nucba.primary'
              position='absolute'
              top={0}
              left={-1}
            />
            <Box
              _hover={{
                textDecoration: 'underline',
                color: 'nucba.primary',
                cursor: 'pointer',
              }}
            >
              <Link to='/mi-cupon' color='white'>
                Referí a un amigo
              </Link>
            </Box>
          </HStack>

          <HeaderNovu />
          <Isologo />
        </HStack>
        <Stack
          w={{ base: '70%', '2xl': '75%' }}
          height='100%'
          mx='auto'
          justify='center'
          spacing={5}
        >
          <Text as='p' fontSize={{ base: 'lg', '2xl': 'xl' }} lineHeight={1}>
            Hola {firstname}, que placer verte otra vez! 👋{' '}
          </Text>
          <HStack>
            <PageHeading
              fontSize={{ base: '4xl', '2xl': '6xl' }}
              lineHeight={1}
            >
              NUCBA 101
            </PageHeading>
            <Text
              lineHeight={1}
              display='inline-block'
              fontSize={{ base: 35, '2xl': 64 }}
            >
              🖥️
            </Text>
          </HStack>
          <Text as='p' fontSize={'lg'}>
            📌¡Accedé a nuestra sección introductoria! <br />
          </Text>
          <Box>
            <ButtonSecondary
              onClick={() => openInNewTab('/blog?section=live-coding-101')}
            >
              Ir al blog
            </ButtonSecondary>
          </Box>
        </Stack>
      </Hero>

      <MyCohortes />
    </>
  )
}
