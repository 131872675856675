import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Box,
  Center,
  Grid,
  GridItem,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { Card } from '@nucba-coding/nucba-ui'

import { PageHeading } from '../../shared/components/PageHeading'
import { slugifyCourseName } from '../../shared/utils'
import {
  useGetCertificateRegularStudentQuery,
  useGetCohortCoursesFirstClassesQuery,
  useGetCohortCoursesQuery,
} from '../services/cohort.service'
import { useStudent } from '@/shared/hooks'
import { useCoursePrefetch } from '../services/course.service'
import { useAppSelector } from '@/shared/app/hooks'
import { sessionState } from '@/shared/features/session.slice'
import { CohortCoursesLoader } from '@/education/shared/components/loaders/CohortCoursesLoader'
import { CohortClassStatus } from '@/shared/enums'
import { DateTime } from '@/shared/helpers'
import { COHORT_TYPE } from '@/shared/types'
import { AsyncCourses } from './AsyncCourses'
import { ProductCourse } from '../types/cohort-courses.types'
import { CertificadoPdf } from './StudentRegularCertificate'

export const CohortCourses = () => {
  const { code } = useParams()
  const navigate = useNavigate()
  const { studentId, expelledAt } = useStudent(code!)
  const { id, isStaff } = useAppSelector(sessionState)

  const prefetchCourseDetails = useCoursePrefetch('getCourseDetails')

  const { data, isLoading } = useGetCohortCoursesQuery({
    code: code!,
    studentId,
  })

  const { data: certificate, isLoading: isCertificateLoading } =
    useGetCertificateRegularStudentQuery({
      studentId,
    })

  const { data: coursesFirstClasses, isLoading: isCoursesFirstClassesLoading } =
    useGetCohortCoursesFirstClassesQuery(
      {
        code: code!,
        studentId,
      },
      {
        skip: !expelledAt,
      },
    )

  const syncCourses =
    data?.cohorts[0]._products_cohorts
      .flatMap(({ product }) => product._product_courses)
      .filter(({ course }) => !course.isAsync) ?? []

  const asyncCourses =
    data?.cohorts[0]._products_cohorts
      .flatMap(({ product }) => product._product_courses)
      .filter(({ course }) => course.isAsync) ?? []

  const certificateLink = certificate && (
    <PDFDownloadLink
      document={
        <CertificadoPdf
          user={{
            users: [
              {
                profiles: [
                  {
                    firstname:
                      certificate?.users?.[0]?.profiles?.[0]?.firstname || '',
                    lastname:
                      certificate?.users?.[0]?.profiles?.[0]?.lastname || '',
                    document:
                      certificate?.users?.[0]?.profiles?.[0]?.document || '',
                    addresses: [
                      {
                        street:
                          certificate?.users?.[0]?.profiles?.[0]?.addresses?.[0]
                            ?.street || '',
                        number:
                          certificate?.users?.[0]?.profiles?.[0]?.addresses?.[0]
                            ?.number || '',
                        locality:
                          certificate?.users?.[0]?.profiles?.[0]?.addresses?.[0]
                            ?.locality || '',
                        city:
                          certificate?.users?.[0]?.profiles?.[0]?.addresses?.[0]
                            ?.city || '',
                        province:
                          certificate?.users?.[0]?.profiles?.[0]?.addresses?.[0]
                            ?.province || '',
                        country:
                          certificate?.users?.[0]?.profiles?.[0]?.addresses?.[0]
                            ?.country || '',
                      },
                    ],
                  },
                ],
                cohort_integrants: [
                  {
                    product: {
                      name:
                        certificate?.users?.[0]?.cohort_integrants?.[0]?.product
                          ?.name || '',
                      type:
                        certificate?.users?.[0]?.cohort_integrants?.[0]?.product
                          ?.type || '',
                    },
                    cohort: {
                      type:
                        certificate?.users?.[0]?.cohort_integrants?.[0]?.cohort
                          ?.type || COHORT_TYPE.ON_DEMAND,
                      days:
                        certificate?.users?.[0]?.cohort_integrants?.[0]?.cohort
                          ?.days || 0,
                      startDate:
                        certificate?.users?.[0]?.cohort_integrants?.[0]?.cohort
                          ?.startDate || '',
                      code:
                        certificate?.users?.[0]?.cohort_integrants?.[0]?.cohort
                          ?.code || '',
                      id:
                        certificate?.users?.[0]?.cohort_integrants?.[0]?.cohort
                          ?.id || '',
                    },
                  },
                ],
              },
            ],
          }}
        />
      }
      fileName={`Certificado_Alumno_Regular.pdf`}
    >
      <Text as='span' cursor='pointer' _hover={{ textDecoration: 'underline' }}>
        Descargar certificado de alumno regular
      </Text>
    </PDFDownloadLink>
  )

  return (
    <>
      {syncCourses.length > 0 && (
        <>
          <HStack w='95%' display='flex' justifyContent='space-between' mb={5}>
            <PageHeading mb={3}>Módulos en vivo</PageHeading>
            {isCertificateLoading ? (
              <Text>Cargando certificado...</Text>
            ) : (
              certificateLink
            )}
          </HStack>
          <Grid
            templateColumns={{
              base: 'repeat(auto-fit, 250px)',
              '2xl': 'repeat(auto-fit, 330px)',
            }}
            gap={10}
            mb={3}
          >
            {/* SYNC COURSES */}
            {isLoading || isCoursesFirstClassesLoading ? (
              <CohortCoursesLoader />
            ) : (
              syncCourses.map(({ course }: ProductCourse, index) => {
                const product = data?.cohorts[0]._products_cohorts[0].product

                const isAble =
                  isStaff ||
                  !index ||
                  product!._product_courses[index - 1].course.classes[0]
                    .cohort_classes[0]?.status === CohortClassStatus.FINALIZADA

                if (expelledAt) {
                  const existCourse =
                    coursesFirstClasses?._products_cohorts.flatMap(
                      ({ product }) =>
                        product._product_courses.filter(
                          ({ course: _course }) => _course.id === course.id,
                        ),
                    )

                  if (existCourse) {
                    const firstClassDate =
                      existCourse[0].course.classes[0].cohort_classes[0].date

                    const isDisabled = DateTime.createFromDate(
                      new Date(expelledAt!),
                    ).lowerThan(
                      DateTime.createFromDate(new Date(firstClassDate)),
                    )

                    if (isDisabled) {
                      return null
                    }
                  }
                }

                return isAble ? (
                  <GridItem
                    key={course.id}
                    onClick={() =>
                      navigate(`curso/${slugifyCourseName(course.name)}`, {
                        state: {
                          courseId: course.id,
                          courseName: course.name,
                          cohortType: data?.cohorts.at(0)?.type,
                        },
                      })
                    }
                    onMouseEnter={() =>
                      prefetchCourseDetails({ courseId: course.id, userId: id })
                    }
                  >
                    <Card
                      w='auto'
                      h={{ base: 260, '2xl': 400 }}
                      title={course.name}
                      bgImage={course.image}
                    />
                  </GridItem>
                ) : (
                  <GridItem key={course.id} pos='relative' cursor='not-allowed'>
                    <Card
                      w='auto'
                      h={{ base: 260, '2xl': 400 }}
                      title={course.name}
                      bgImage={course.image}
                    />
                    <Center
                      pos='absolute'
                      inset={0}
                      w='full'
                      h={{ base: 260, '2xl': 400 }}
                      backdropFilter='blur(3px);'
                    >
                      <Text
                        fontSize='xl'
                        textAlign='center'
                        wordBreak='keep-all'
                      >
                        Próximamente disponible
                      </Text>
                    </Center>
                  </GridItem>
                )
              })
            )}
          </Grid>
        </>
      )}

      {!expelledAt ? (
        <Box mb={10}>
          {asyncCourses.length > 0 ? (
            <PageHeading mb={5} mt={10}>
              Módulos asíncronos
            </PageHeading>
          ) : null}

          {/* ASYNC COURSES */}
          {isLoading || isCoursesFirstClassesLoading ? (
            <></>
          ) : (
            asyncCourses.length > 0 && (
              <AsyncCourses
                courses={asyncCourses.map(({ course }) => course)}
                cohortType={data?.cohorts.at(0)?.type as COHORT_TYPE}
              />
            )
          )}
        </Box>
      ) : (
        <VStack marginBlock={10} alignItems='self-start'>
          <Text fontSize='xl'>
            No tienes acceso al resto de los cursos debido a tu acceso
            restringido. 🥲
          </Text>
          <Text fontSize='xl'>
            Si crees que es un error o tienes alguna consulta, comunícate con
            secretaría. 🙌
          </Text>
        </VStack>
      )}
    </>
  )
}
