import React, { Dispatch, SetStateAction } from 'react'
import { Link, useParams, useLocation } from 'react-router-dom'
import { HStack, Text } from '@chakra-ui/react'
import { AnimatePresence, motion } from 'framer-motion'
import { NucBookOpen, NucVideo } from '@nucba-coding/nucba-ui'

import { SubClassCard } from './SubClassCard'
import { CardContainer } from '../../shared/components/ClassCardContainer'
import { ClassCardContent } from '../../shared/components/ClassCardContent'

import { AssignmentStatus, CohortClassStatus } from '@/shared/enums'
import { useAppSelector } from '@/shared/app/hooks'
import { sessionState } from '@/shared/features/session.slice'
import { DateTime } from '@/shared/helpers'
import { useNucbaTheme } from '@/shared/hooks'
import { CLASSES_STATUS_COLOR } from '@/shared/constants/classesStatusColors'
import { slugifyCourseName, formatStatus } from '@/education/shared/utils'
import { ATTENDANCE_STATUS, COHORT_TYPE } from '@/shared/types'

type ClassCardProps = {
  i: number
  classDetail: {
    id: string
    name: string
    exercises: {
      id: string
      name: string
      users_assignments: {
        id: string
        status: AssignmentStatus
      }[]
    }[]
  }
  date: Date
  status: CohortClassStatus
  remarkClass: boolean
  expanded: false | number
  setExpanded: Dispatch<SetStateAction<number | false>>
  simplifiedVersion?: boolean
  cohortClassID: string
  cohortClassName: string
  studentAttendance?: ATTENDANCE_STATUS
  courseIsAsync: boolean
  cohortType: COHORT_TYPE
}

export const ClassCard = ({
  i,
  classDetail,
  date,
  remarkClass,
  expanded,
  status,
  setExpanded,
  simplifiedVersion,
  cohortClassID,
  cohortClassName,
  studentAttendance,
  courseIsAsync,
  cohortType,
}: ClassCardProps) => {
  const { colors } = useNucbaTheme()
  const { code, course } = useParams()
  const { state } = useLocation()
  const { isStaff } = useAppSelector(sessionState)

  const hasExercise = classDetail.exercises.length
  const isOpen = i === expanded

  return (
    <>
      <CardContainer
        as={motion.div}
        initial={false}
        w={simplifiedVersion ? '97%' : '100%'}
        pos='relative'
        outline={remarkClass ? `2px solid ${colors.nucba.primary}` : 'none'}
        onClick={
          hasExercise ? () => setExpanded(isOpen ? false : i) : () => null
        }
        simplifiedVersion={simplifiedVersion}
        hasExercise={hasExercise}
      >
        <ClassCardContent
          heading={cohortClassName ? cohortClassName : classDetail.name}
          description={
            hasExercise &&
            (!courseIsAsync || cohortType === COHORT_TYPE.ON_DEMAND)
              ? `${hasExercise} ${hasExercise > 1 ? 'Entregas' : 'Entrega'} `
              : ''
          }
          textStyles={hasExercise && isStaff ? { color: '#FFB800' } : {}}
          simplifiedVersion={simplifiedVersion}
          iconIsActive={
            studentAttendance === ATTENDANCE_STATUS.RECORDING_VIEWED
          }
          showIcon={courseIsAsync}
        >
          <HStack spacing={5} gap={2} divider={<span>|</span>}>
            {courseIsAsync ? (
              <NucVideo width='15' height='15' />
            ) : (
              <Text
                fontSize='xs'
                color={status ? CLASSES_STATUS_COLOR[status] : 'nucba.primary'}
              >
                {simplifiedVersion ? '' : 'CLASE'} {formatStatus(status)}
              </Text>
            )}
            {!simplifiedVersion && !courseIsAsync && (
              <HStack gap={1}>
                <Text fontSize='xs'>
                  {DateTime.createFromDate(new Date(date), false).formatDate({
                    dateStyle: 'long',
                  })}
                </Text>
                {hasExercise ? (
                  <NucBookOpen width='15' height='15' />
                ) : (
                  <NucVideo width='15' height='15' />
                )}
              </HStack>
            )}
            <Link
              to={`/camada/${code}/curso/${slugifyCourseName(
                course,
              )}/clase/${cohortClassID}`}
              state={state}
            >
              <Text
                noOfLines={1}
                fontSize={simplifiedVersion ? 'small' : 'sm'}
                lineHeight={1}
                textDecoration='underline'
                _hover={{
                  color: colors.nucba.primary,
                }}
                minW={'75px'}
              >
                Ver clase
              </Text>
            </Link>
          </HStack>
        </ClassCardContent>
      </CardContainer>

      <AnimatePresence initial={false}>
        {isOpen && !simplifiedVersion && (
          <motion.section
            key='content'
            initial='collapsed'
            animate='open'
            exit='collapsed'
            variants={{
              open: { height: 'auto', marginTop: '20px' },
              collapsed: {
                height: 0,
                marginTop: 0,
                transition: {
                  delay: 0.2,
                },
              },
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'end',
              gap: '20px',
              width: simplifiedVersion ? '80%' : '100%',
            }}
          >
            <SubClassCard
              subClasses={[
                /* {
                  name: classDetail.name,
                }, */
                ...classDetail.exercises.map(exercise =>
                  isStaff
                    ? { name: `📋 ${exercise.name}`, id: exercise.id }
                    : exercise.users_assignments.length
                      ? {
                          id: exercise.users_assignments[0].id,
                          name: `📋 ${exercise.name}`,
                          status: exercise.users_assignments[0].status,
                          date,
                          isGenerated: true,
                        }
                      : {
                          id: exercise.id,
                          name: `📋 ${exercise.name}`,
                          date,
                          isGenerated: false,
                        },
                ),
              ]}
            />
          </motion.section>
        )}
      </AnimatePresence>
    </>
  )
}
