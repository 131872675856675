import { RootState } from '@/shared/app/store'
import { CACHE_TIME } from '@/shared/constants'
import { createApi } from '@reduxjs/toolkit/query/react'
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'
import { gql } from 'graphql-request'

type Coupon = {
  code: string
  activateAt: string | null
  createdAt: string
  current: boolean
  description: string | null
  discount: number
  id: string
  isCumulative: boolean
  name: string
  userId: string
  subsCount: {
    aggregate: {
      count: number
    }
  }
}

type GetCouponsResponse = {
  coupons: Coupon[]
}

export const couponQueryApi = createApi({
  reducerPath: 'couponQueryApi',
  baseQuery: graphqlRequestBaseQuery({
    url: `${process.env.REACT_APP_GRAPHQL_URL}/graphql`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).session.token

      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }

      return headers
    },
  }),
  keepUnusedDataFor: CACHE_TIME,
  endpoints: builder => ({
    getCoupons: builder.query<Coupon[], { userId: string }>({
      query: ({ userId }) => ({
        document: gql`
          query GetReferralCouponQuery($userId: String) {
            coupons(where: { userId: { _eq: $userId } }) {
              code
              activateAt
              createdAt
              current
              description
              discount
              id
              isCumulative
              name
              userId
              subsCount: subscriptions_aggregate(
                where: { studentId: { _is_null: false } }
              ) {
                aggregate {
                  count
                }
              }
            }
          }
        `,
        variables: {
          userId,
        },
      }),
      transformResponse: (response: GetCouponsResponse) => {
        console.log('Transforming response:', response)
        if (!response?.coupons) {
          throw new Error('Invalid response format')
        }
        return response.coupons
      },
    }),
  }),
})

export const { useGetCouponsQuery } = couponQueryApi
